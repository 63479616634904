import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMagnifyingGlass,
  faRotateRight,
  faSync
} from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'

import data from '../constants/data'
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  Avatar,
  IconButton,
  Select,
  Option,
  Tooltip
} from '@material-tailwind/react'

const CurrencyRateTable = ({
  exchangeRates,
  banks,
  currencies,
  selectedBanks,
  setSelectedBanks,
  setSelectedDate,
  selectedDate,
  loading
}) => {
  const [showDate, setShowDate] = useState(false)


  const [selectedCurrency, setSelectedCurrency] = useState('USD')
  const [selectedBank, setSelectedBank] = useState(null)

  const handleBankChange = val => {
    setSelectedBanks(formattedBanks.filter(bank => bank.value === val))
    setSelectedBank(val)
  }
  const handleCurrencyChange = val => setSelectedCurrency(val)

  const formattedBanks = banks
    .filter(bank => bank)
    .map(bank => ({
      value: bank,
      label: bank
        .replace(/_/g, ' ')
        .replace(/\b\w/g, char => char.toUpperCase())
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const [sortConfig, setSortConfig] = useState({
    currency: 'USD',
    type: 'buy',
    direction: 'desc'
  })

  useEffect(() => {
    if (selectedBanks.length === 0) {
      const sortedBanks = [...formattedBanks].sort((a, b) => {
        const bankA = exchangeRates[a.value]?.['USD']?.['buy'] || 0
        const bankB = exchangeRates[b.value]?.['USD']?.['buy'] || 0
        return bankB - bankA
      })
      setSelectedBanks(sortedBanks)
    }
  }, [banks, exchangeRates])

  const sortTable = (currency, type, isBank = false, isSpread = false) => {
    let direction = 'desc';
    
    if (isBank) {
      direction = sortConfig.currency === 'bank' && sortConfig.direction === 'asc' ? 'desc' : 'asc';
      setSortConfig({ currency: 'bank', type: 'bank', direction });
    } else if (isSpread) {
      direction = sortConfig.currency === 'spread' && sortConfig.direction === 'asc' ? 'desc' : 'asc';
      setSortConfig({ currency: 'spread', type: 'spread', direction });
    } else {
      direction = sortConfig.currency === currency && sortConfig.type === type && sortConfig.direction === 'asc' ? 'desc' : 'asc';
      setSortConfig({ currency, type, direction });
    }
  
    const sortedBanks = [...selectedBanks].sort((a, b) => {
      if (isBank) {
        if (direction === 'asc') {
          return a.label.localeCompare(b.label);
        } else {
          return b.label.localeCompare(a.label);
        }
      } else if (isSpread) {
        const spreadA = calculateSpreadPercentage(
          exchangeRates[a.value]?.[selectedCurrency]?.buy,
          exchangeRates[a.value]?.[selectedCurrency]?.sell
        );
        const spreadB = calculateSpreadPercentage(
          exchangeRates[b.value]?.[selectedCurrency]?.buy,
          exchangeRates[b.value]?.[selectedCurrency]?.sell
        );
  
        if (direction === 'asc') {
          return parseFloat(spreadA) - parseFloat(spreadB);
        } else {
          return parseFloat(spreadB) - parseFloat(spreadA);
        }
      } else {
        const bankA = exchangeRates[a.value]?.[currency]?.[type] || 0;
        const bankB = exchangeRates[b.value]?.[currency]?.[type] || 0;
  
        if (direction === 'asc') {
          return bankA - bankB;
        } else {
          return bankB - bankA;
        }
      }
    });
  
    setSelectedBanks(sortedBanks);
  };

  const getSortIndicator = (currency, type) => {
    if (sortConfig.currency === currency && sortConfig.type === type) {
      return sortConfig.direction === 'asc' ? '↑' : '↓'
    }
    return ''
  }

  const isBankAllNA = bank => {
    return orderedCurrencies.every(
      currency =>
        !exchangeRates[bank]?.[currency]?.buy &&
        !exchangeRates[bank]?.[currency]?.sell
    )
  }

  const getLastUpdatedDate = bank => {
    const dates = Object.keys(exchangeRates[bank] || {}).map(
      currency => exchangeRates[bank][currency]?.date
    )
    return dates.length ? dates.sort().reverse()[0] : 'N/A'
  }

  const calculateSpreadPercentage = (buy, sell) => {
    if (!buy || !sell) return 'N/A'
    return (((sell - buy) / buy) * 100).toFixed(2) + '%'
  }

  const preferredOrder = [
    'USD',
    'GBP',
    'EUR',
    'CAD',
    'AED',
    'SAR',
    'CHF',
    'AUD',
    'CNY'
  ]
  const orderedCurrencies = [
    ...preferredOrder,
    ...currencies.filter(currency => !preferredOrder.includes(currency))
  ]

  return (
    <Card className='h-full w-full '>
      <CardHeader
        floated={false}
        shadow={false}
        className='rounded-none  overflow-visible'
      >
        <div className='mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center'>
          <div>
            <Typography variant='h5' color='blue-gray'>
              Currency Exchange Rates
            </Typography>
            <Typography color='gray' className='mt-1 font-normal text-xs'>
              Last updated at: {new Date().toDateString()}
            </Typography>
          </div>
          <div className='flex flex-wrap items-center w-full shrink-0 gap-2 md:w-max'>
            <div className='relative'>
              <Button
                size='lg'
                onClick={() => setShowDate(!showDate)}
                className='bg-secondary capitalize hover:bg-secondary/80 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-[150px]'
              >
                Select Date
              </Button>
              {showDate && (
                <div className='absolute z-[10]'>
                  <DatePicker
                    inline
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    className='mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                    isClearable
                    placeholderText='Select a date'
                  />
                </div>
              )}
            </div>
            <div className='flex w-full sm:w-max items-center gap-0'>
              <Select
                onChange={handleBankChange}
                value={formattedBanks[0]?.value || ''}
                size='md'
                label='Select Bank'
                selected={element =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      'flex items-center flex-row opacity-100 px-0 gap-2 pointer-events-none'
                  })
                }
              >
                {formattedBanks.map((bank, index) => (
                  <Option
                    key={index}
                    value={bank.value}
                    className='flex items-center gap-2'
                  >
                    <img
                      src={data.bankLogos[bank.value]}
                      alt={bank.label}
                      className='h-5 w-5 rounded-full object-cover'
                    />
                    {bank.label}
                  </Option>
                ))}
              </Select>
              <IconButton
                onClick={() => {
                  setSelectedBanks(formattedBanks)
                  console.log(JSON.parse(localStorage.getItem('selectedBanks')))
                }}
                variant='text'
              >
                <FontAwesomeIcon icon={faSync} className='mr-2' />{' '}
              </IconButton>
            </div>
            <div className='w-full sm:w-max'>
              <Select
                onChange={handleCurrencyChange}
                value={selectedCurrency}
                size='md'
                label='Select Currency'
                selected={element =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      'flex items-center flex-row opacity-100 px-0 gap-2 pointer-events-none'
                  })
                }
              >
                {orderedCurrencies.map((currency, index) => (
                  <Option
                    key={index}
                    value={currency}
                    className='flex items-center gap-2'
                  >
                    <img
                      src={data.currencyIcons[currency]}
                      className='h-5 w-5 rounded-full object-cover'
                    />
                    {currency}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </CardHeader>

      {loading ? (
        <div className='w-full h-[200px] flex items-center justify-center'>
          <p>Loading ...</p>
        </div>
      ) : (
        <CardBody className='overflow-scroll px-0 '>
          <table className='w-full table-auto text-left '>
            <thead>
              <tr>
                <th
                  onClick={() => sortTable(null, null, true)}
                  className='border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 cursor-pointer'
                >
                  <Typography
                    variant='h6'
                    color='blue-gray'
                    className='font-normal leading-none opacity-70'
                  >
                    Bank Name
                    {sortConfig.currency === 'bank'
                      ? sortConfig.direction === 'asc'
                        ? '↑'
                        : '↓'
                      : ''}
                  </Typography>
                </th>
                <th
                  onClick={() => sortTable(selectedCurrency, 'buy')}
                  className='border-y cursor-pointer border-blue-gray-100 bg-blue-gray-50/50 p-4'
                >
                  <Typography
                    variant='h6'
                    color='blue-gray'
                    className='font-normal leading-none opacity-70'
                  >
                    Buying {getSortIndicator(selectedCurrency, 'buy')}
                  </Typography>
                </th>
                <th
                  onClick={() => sortTable(selectedCurrency, 'sell')}
                  className='border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 cursor-pointer'
                >
                  <Typography
                    variant='h6'
                    color='blue-gray'
                    className='font-normal leading-none opacity-70'
                  >
                    Selling {getSortIndicator(selectedCurrency, 'sell')}
                  </Typography>
                </th>
                <th
                  onClick={() => sortTable(selectedCurrency, null, false, true)}
                  className='border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 cursor-pointer'
                >
                  <Typography
                    variant='h6'
                    color='blue-gray'
                    className='font-normal leading-none opacity-70'
                  >
                    Spread {getSortIndicator('spread', 'spread')}
                  </Typography>
                </th>
                <th className='border-y border-blue-gray-100 bg-blue-gray-50/50 p-4'>
                <Tooltip content="The last date the bank updated the data">
                  <Typography
                    variant='h6'
                    color='blue-gray'
                    className='font-normal leading-none opacity-70 flex items-center'
                  >
                    <span className="flex items-center gap-1">
                      Last Updated
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-3 w-3 cursor-pointer text-blue-gray"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                    </span>
                  </Typography>
                </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedBanks
                .filter(({ value: bank }) => !isBankAllNA(bank))
                .map(({ value: bank }, index) => {
                  const formattedBank = bank
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, char => char.toUpperCase())
                  const isLast = index === selectedBanks.length - 1
                  const classes = isLast
                    ? 'p-4'
                    : 'p-4 border-b border-blue-gray-50'

                  const rowClasses = bank === "NBE" 
                    ? `${classes} bg-gray-100`
                    : classes
                  return (
                    <tr key={bank} className={rowClasses}>
                      <td className={classes}>
                        <div className='flex items-center gap-3'>
                          <Avatar
                            src={data.bankLogos[bank]}
                            alt={formattedBank}
                            size='md'
                            className='border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1'
                          />
                          <Typography
                            variant='small'
                            color='blue-gray'
                            className='font-bold'
                          >
                            {formattedBank}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className='w-max'>
                          <Chip
                            className='text-[15px]'
                            size='lg'
                            variant='ghost'
                            value={
                              exchangeRates[bank]?.[selectedCurrency]?.buy ||
                              'N/A'
                            }
                            color='green'
                          />
                        </div>
                      </td>
                      <td className={classes}>
                        <div className='w-max'>
                          <Chip
                            size='lg'
                            className='text-[15px]'
                            variant='ghost'
                            value={
                              exchangeRates[bank]?.[selectedCurrency]?.sell ||
                              'N/A'
                            }
                            color='red'
                          />
                        </div>
                      </td>

                      {selectedCurrency && (
                        <td className={classes}>
                          <div className='w-max'>
                            <Typography
                              variant='small'
                              color='blue-gray'
                              className='font-normal'
                            >
                              {calculateSpreadPercentage(
                                exchangeRates[bank]?.[selectedCurrency]?.buy,
                                exchangeRates[bank]?.[selectedCurrency]?.sell
                              )}
                            </Typography>
                          </div>
                        </td>
                      )}

                      <td className={classes}>
                        <div className='flex items-center gap-3'>
                          <Typography
                            variant='small'
                            color='blue-gray'
                            className='font-normal'
                          >
                            {getLastUpdatedDate(bank)}
                          </Typography>
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </CardBody>
      )}
    </Card>
  )
}

export default CurrencyRateTable
