import React, { useState, useEffect } from "react";
import Timeline from "./Timeline";
import EventDetails from "./EventDetails";

const TimelinePage = () => {
  const [timeline, setTimeline] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    fetch("/timeline.json")
      .then((response) => response.json())
      .then((data) => setTimeline(data));
  }, []);

  const showEventDetails = (event) => {
    setSelectedEvent(event);
  };

  const closeEventDetails = () => {
    setSelectedEvent(null);
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Timeline</h1>
      {timeline && (
        <Timeline
          timeline={timeline}
          sortAscending={false}
          showEventDetails={showEventDetails}
        />
      )}
      {selectedEvent && (
        <EventDetails event={selectedEvent} closeEventDetails={closeEventDetails} />
      )}
    </div>
  );
};

export default TimelinePage;
