import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
  <footer className="text-gray p-4 mt-8">
    <div className="container mx-auto text-center">
      <p className="text-xs text-gray-500">
        <strong>Disclaimer:</strong> The exchange rates provided are based on
        information from various Ethiopian banks. We recommend confirming the rates with
        the respective banks before making any transactions.
      </p>
      <div className="mt-4">
        <a
          href="https://www.linkedin.com/company/birr-exchange?utm_source=footer&utm_medium=link&utm_campaign=linkedin"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-500 hover:text-gray-700"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
      </div>
      <p className="text-sm font-bold text-black mt-2">
        © {new Date().getFullYear()} Birr.Exchange
      </p>
    </div>
  </footer>
);

export default Footer;
