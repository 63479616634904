import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Helmet } from 'react-helmet'
// import ReactGA from 'react-ga'

import Header from './components/Header'
import TimelinePage from './components/TimelinePage'
import HomePage from './components/HomePage'
import GoogleFormEmbed from './components/GoogleFormEmbed'
// import ContactUs from './components/ContractUs'
import { ThemeProvider } from '@material-tailwind/react'
// const TRACKING_ID = 'G-VNHLWKW6C4' // Your Tracking ID

const App = () => {
  // useEffect(() => {
  //   ReactGA.initialize(TRACKING_ID, { debug: false })
  //   ReactGA.pageview(window.location.pathname + window.location.search)
  //   console.log('ReactGA initialized and pageview sent')
  // }, [])
  return (
    <Router>
      <ThemeProvider>
        <Header />
        <div className='pt-16'>
          <Helmet>
            <title>Birr Exchange Rate</title>
            <meta
              name='description'
              content='Get the latest exchange rates for Birr to other currencies. All Ethiopian Banks Exchange Rate.'
            />
            <meta
              name='keywords'
              content='Birr, currency exchange, exchange rates, forex, Birr to USD, Birr to EUR'
            />
            <meta name='author' content='birr.exchange' />
            <meta property='og:title' content='Birr Exchange Rate' />
            <meta
              property='og:description'
              content='Get the latest exchange rates for Birr to other currencies. All Ethiopian Banks Exchange Rate.'
            />
            <meta property='og:url' content='https://birr.exchange' />
            <meta property='og:type' content='website' />
            <meta property='og:image' content='/android-chrome-512x512.png' />
          </Helmet>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/timeline' element={<TimelinePage />} />
            <Route path='/contact' element={<GoogleFormEmbed />} />
          </Routes>
        </div>
      </ThemeProvider>
    </Router>
  )
}

export default App
