import React, { useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import data from '../constants/data'

const { auth_token } = data;

const LatestUpdates = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [markdownContent, setMarkdownContent] = useState("")
  const [lastUpdated, setLastUpdated] = useState("")

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await fetch('https://birr-exchange-backend.vercel.app/api/v1/report/', {
            headers: {
              'Authorization': `Bearer ${auth_token}`
            }
          }
        )
        const data = await response.json()
        setMarkdownContent(data.Report)
        setLastUpdated(data.Date)
      } catch (error) {
        console.error('Error fetching report:', error)
      }
    }

    fetchReport()
  }, [])

  return (
    <div className='w-full px-6 md:px-0 m-auto md:max-w-5xl flex flex-col mt-4 items-start'>
      <button
        onClick={toggleVisibility}
        className='text-gray-500 hover:text-gray-700 flex items-center gap-2'
      >
        {isVisible ? (
          <FontAwesomeIcon icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
        <span>{isVisible ? 'Hide Updates' : 'Show Updates'}</span>
      </button>

      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className='w-full flex flex-col gap-5 items-start bg-secondary/10 p-4 rounded-md overflow-hidden'
          >
            <h2 className='font-semibold text-lg'>Latest Updates</h2>
            <p className="text-xs text-gray-500">
              Updated at: {new Date(lastUpdated).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              })}
            </p>
            <ReactMarkdown 
              className='prose text-gray-700' 
              children={markdownContent} 
              remarkPlugins={[remarkGfm]} 
              components={{
                ul: ({node, ...props}) => <ul className="list-disc pl-5" {...props} />,
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default LatestUpdates
