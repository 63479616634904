import React from 'react'
import moment from 'moment'
import { PiXLight } from 'react-icons/pi'
import { Button } from '@material-tailwind/react'
function EventDetails ({ event, closeEventDetails }) {
  if (!event) return null

  const placeholderImage =
    'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png'

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-0  md:p-16 overflow-auto z-[51]'>
      <div className='bg-white h-full p-6 pt-16 rounded-lg shadow-lg w-full md:w-2/3 lg:w-1/2 relative overflow-y-scroll'>
        <div className='absolute top-2 right-2 text-gray-500 '>
          <Button variant='text' size='sm' onClick={closeEventDetails}>
            <PiXLight size={20} color='black' />
          </Button>
        </div>
        <div className='text-blue-500 font-semibold mb-2'>
          {moment(event.date).format('MMMM D, YYYY')}
        </div>
        <h2 className='text-2xl font-semibold mb-4'>{event.subtitle}</h2>
        <p className='text-gray-600 mb-4 whitespace-pre-line'>
          {event.description}
        </p>
        <img
          src={event.image || placeholderImage}
          alt='Event'
          className='rounded w-full'
        />
      </div>
    </div>
  )
}

export default EventDetails
