import React, { useState, useEffect } from 'react'
import {
  CardHeader,
  Typography,
  CardBody,
  Chip,
  Avatar,
  Select,
  Option
} from '@material-tailwind/react'
import { data } from '../constants'
const TABLE_HEAD = ['Bank Name', 'Buying', 'Selling', 'Spread', 'Last Updated']

const BestTransaction = ({ exchangeRates, banks, currencies }) => {
  const [topSpreads, setTopSpreads] = useState([])

  const [selectedCurrency, setSelectedCurrency] = useState('USD')

  const handleCurrencyChange = val => setSelectedCurrency(val)

  const calculateSpreadPercentage = (buy, sell) => {
    if (!buy || !sell) return 'N/A'
    return (((sell - buy) / buy) * 100).toFixed(2) + '%'
  }

  const preferredOrder = [
    'USD',
    'GBP',
    'EUR',
    'CAD',
    'AED',
    'SAR',
    'CHF',
    'AUD',
    'CNY'
  ]
  const orderedCurrencies = [
    ...preferredOrder,
    ...currencies.filter(currency => !preferredOrder.includes(currency))
  ]

  useEffect(() => {
    if (!exchangeRates || !selectedCurrency) return

    const spreads = []

    Object.entries(exchangeRates).forEach(([bank, currencies]) => {
      if (currencies[selectedCurrency]) {
        const { buy, sell, date } = currencies[selectedCurrency]
        const spread = calculateSpreadPercentage(buy, sell)
        spreads.push({
          bank: bank,
          selectedCurrency,
          spread,
          buy,
          sell,
          date
        })
      }
    })

    const sortedSpreads = spreads.sort(
      (a, b) =>
        Number(b.spread.replace('%', '')) - Number(a.spread.replace('%', ''))
    )

    const tSpreads = sortedSpreads.slice(0, 5)

    setTopSpreads(tSpreads)
  }, [exchangeRates, selectedCurrency])
  return (
    <div className='h-full w-full md:w-[80%] '>
      <CardHeader
        floated={false}
        shadow={false}
        className='rounded-none  overflow-visible bg-transparent'
      >
        <div className='mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center'>
          <div>
            <Typography variant='h5' color='blue-gray'>
              Banks With the Highest Spread Value
            </Typography>
            <div>
              <Typography color='gray' className='mt-1 font-normal text-xs'>
                Last Updated on {new Date().toDateString()}
              </Typography>
            </div>
          </div>
          <div className='flex flex-col items-center w-full shrink-0 gap-2 md:w-max'>
            <div className='w-full sm:w-max'>
              <Select
                onChange={handleCurrencyChange}
                value={selectedCurrency}
                size='md'
                label='Select Currency'
                selected={element =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      'flex items-center flex-row opacity-100 px-0 gap-2 pointer-events-none'
                  })
                }
              >
                {orderedCurrencies.map((currency, index) => (
                  <Option
                    key={index}
                    value={currency}
                    className='flex items-center gap-2'
                  >
                    <img
                      src={data.currencyIcons[currency]}
                      className='h-5 w-5 rounded-full object-cover'
                    />
                    {currency}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody className='overflow-scroll p-0 bg-white '>
        <table className='w-full table-auto text-left '>
          <thead>
            <tr>
              {TABLE_HEAD.map(head => (
                <th
                  key={head}
                  className='border-y border-blue-gray-100 bg-blue-gray-50/50 p-4'
                >
                  <Typography
                    variant='small'
                    color='blue-gray'
                    className='font-normal leading-none opacity-70'
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {topSpreads?.map(({ bank, buy, sell, spread, date }, index) => {
              const isLast = index === topSpreads.length - 1
              const classes = isLast
                ? 'p-4'
                : 'p-4 border-b border-blue-gray-50'
              const formattedBank = bank
                .replace(/_/g, ' ')
                .replace(/\b\w/g, char => char.toUpperCase())
              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className='flex items-center gap-3'>
                      <Avatar
                        src={data.bankLogos[bank]}
                        alt={bank}
                        size='md'
                        className='border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1'
                      />
                      <Typography
                        variant='small'
                        color='blue-gray'
                        className='font-bold'
                      >
                        {formattedBank}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className='w-max'>
                      <Chip
                        size='lg'
                        variant='ghost'
                        value={buy}
                        color='green'
                      />
                    </div>
                  </td>
                  <td className={classes}>
                    <div className='w-max'>
                      <Chip
                        size='lg'
                        variant='ghost'
                        value={sell}
                        color='red'
                      />
                    </div>
                  </td>
                  <td className={classes}>
                    <div className='w-max'>
                      <Typography
                        variant='small'
                        color='blue-gray'
                        className='font-normal'
                      >
                        {spread}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className='flex items-center gap-3'>
                      <Typography
                        variant='small'
                        color='blue-gray'
                        className='font-normal'
                      >
                        {date}
                      </Typography>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </CardBody>
    </div>
  )
}

export default BestTransaction
