import { Button } from '@material-tailwind/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { PiXLight } from 'react-icons/pi'
const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false)
  const conversionResultVariants = {
    hidden: {
      y: '-30vh',
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 0.3
      }
    },
    exit: {
      y: '-30vh',
      opacity: 0,
      transition: {
        type: 'tween',
        duration: 0.3,
        delay: 0.3
      }
    }
  }

  return (
    <header className='fixed top-0 left-0 w-full bg-white bg-opacity-50 backdrop-blur-md border-gray border-b-[2px] z-50'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex justify-between items-center h-16'>
          <Link to={'/'} className='flex-shrink-0'>
            <img
              src='/logo.png'
              alt='Birr.Exchange Logo'
              className='w-12 h-12'
            />
          </Link>
          <nav className='hidden md:flex space-x-8'>
            <Link
              className='text-gray-800 hover:bg-gray-00   px-4 py-1 hover:bg-gray-100 transition duration-300'
              to='/'
            >
              Home
            </Link>
            <Link
              className='text-gray-800 px-4 py-1 hover:bg-gray-100 transition duration-300'
              to='/timeline'
            >
              Timeline
            </Link>
            <Link
              className='text-gray-800 hover:text-gray-600  px-4 py-1 hover:bg-gray-100 transition duration-300'
              to='/contact'
            >
              Contact
            </Link>
          </nav>
          <AnimatePresence>
            {mobileMenu && (
              <motion.nav
                variants={conversionResultVariants}
                initial='hidden'
                animate='visible'
                exit='exit'
                className='flex flex-col md:hidden gap-2 shadow-lg  w-full bg-white fixed top-16 left-0'
              >
                <Link
                  className='text-gray-800 hover:bg-gray-00  px-4 py-1 hover:bg-gray-100 transition duration-300'
                  to='/'
                >
                  Home
                </Link>
                <Link
                  className='text-gray-800 px-4 py-1 hover:bg-gray-100 transition duration-300'
                  to='/timeline'
                >
                  Timeline
                </Link>
                <Link
                  className='text-gray-800 hover:text-gray-600  px-4 py-1 hover:bg-gray-100 transition duration-300'
                  to='/contact'
                >
                  Contact
                </Link>
              </motion.nav>
            )}
          </AnimatePresence>
          <div className='md:hidden'>
            {mobileMenu ? (
              <Button
                variant='text'
                onClick={() => setMobileMenu(false)}
                className='bg-gray-200 bg-opacity-50 backdrop-blur-md p-2 rounded-full hover:bg-gray-300 transition duration-300'
              >
                <PiXLight size={20} />
              </Button>
            ) : (
              <Button
                onClick={() => setMobileMenu(!mobileMenu)}
                variant='text'
                className='bg-gray-200 bg-opacity-50 backdrop-blur-md p-2 rounded-full hover:bg-gray-300 transition duration-300'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className='w-6 h-6 text-gray-800'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
              </Button>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
