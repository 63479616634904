import React from "react";

const GoogleFormEmbed = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSe5yDCN3mnEOFMwbyvepC-ZaRmxv4oLwbhxxcP0pG_Lm1RZzA/viewform?embedded=true"
        title="Contact Form"
        className="w-full h-full max-w-3xl"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </div>
  );
};

export default GoogleFormEmbed;
