import React, { useState, useEffect } from 'react'
import CurrencyConverter from './CurrencyConverter'
import Footer from './Footer'
import dayjs from 'dayjs'
import 'react-datepicker/dist/react-datepicker.css'
import { images } from '../constants'
import CurrencyRateTable from './CurrencyRateTable'
import BestTransaction from './BestTransaction'
import BinanceRate from './BinanceRate'
import LatestUpdates from './LatestUpdates'
// import NBERateTable from './NBERateTable'
import data from '../constants/data'

const { auth_token } = data;

const preferredOrder = [
  'USD',
  'GBP',
  'EUR',
  'CAD',
  'AED',
  'SAR',
  'CHF',
  'AUD',
  'CNY'
]

const HomePage = () => {
  const [exchangeRates, setExchangeRates] = useState({})
  const [historicalData, setHistoricalData] = useState([])
  const [banks, setBanks] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [error, setError] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null) // null for latest rates by default
  const [selectedBanks, setSelectedBanks] = useState([])
  const [selectedCurrencies, setSelectedCurrencies] = useState(preferredOrder)
  const [loading, setLoading] = useState(true)

  const fetchNBERates = async () => {
    try {
      const response = await fetch("https://birr-exchange-backend.vercel.app/api/v1/nbe/", {
          headers: {
            'Authorization': `Bearer ${auth_token}`
          }
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch NBE rates");
      }
      return await response.json();
    } catch (error) {
      console.error("Failed to fetch NBE rates:", error);
      return [];
    }
  };
  
  const fetchExchangeRates = async (date = null) => {
    setLoading(true);
    try {
      const url = date
        ? `https://birr-exchange-backend.vercel.app/api/v1/search/?date=${dayjs(
            date
          ).format("YYYY-MM-DD")}`
        : "https://birr-exchange-backend.vercel.app/api/v1/latest";
      const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${auth_token}`
          }
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
  
      const rates = {};
      const banksSet = new Set();
      const currenciesSet = new Set();
  
      data.forEach((row) => {
        banksSet.add(row.Bank);
        currenciesSet.add(row.Currency);
  
        if (!rates[row.Bank]) {
          rates[row.Bank] = {};
        }
  
        rates[row.Bank][row.Currency] = {
          buy: row.Buying !== null ? parseFloat(row.Buying).toFixed(2) : "N/A",
          sell: row.Selling !== null ? parseFloat(row.Selling).toFixed(2) : "N/A",
          date: row.Date,
        };
      });
  
      // Only fetch NBE data if date is null (latest rates)
      if (date === null) {
        const nbeData = await fetchNBERates();
        nbeData.forEach((row) => {
          const bank = "NBE"; // Label for NBE
          banksSet.add(bank);
          currenciesSet.add(row.Currency);
  
          if (!rates[bank]) {
            rates[bank] = {};
          }
  
          rates[bank][row.Currency] = {
            buy: row.Buying !== null ? parseFloat(row.Buying).toFixed(2) : "N/A",
            sell: row.Selling !== null ? parseFloat(row.Selling).toFixed(2) : "N/A",
            date: row.Date,
          };
        });
      }
  
      setExchangeRates(rates);
      setBanks(Array.from(banksSet));
      setCurrencies(Array.from(currenciesSet));
      if (Array.from(currenciesSet).length > 0) {
        setSelectedCurrency(Array.from(currenciesSet)[0]);
      }
    } catch (error) {
      console.error("Failed to fetch exchange rates:", error);
      setError(error.message);
    }
    setLoading(false);
  };  

  const fetchHistoricalData = async currency => {
    try {
      const today = dayjs().format('YYYY-MM-DD')
      const sevenDaysAgo = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      const response = await fetch(
        `https://birr-exchange-backend.vercel.app/api/v1/search/?start_date=${sevenDaysAgo}&end_date=${today}&currency=${currency}`, {
          headers: {
            'Authorization': `Bearer ${auth_token}`
          }
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()

      const formattedData = formatHistoricalData(data, currency)
      setHistoricalData(formattedData)
    } catch (error) {
      console.error('Failed to fetch historical data:', error)
      setError(error.message)
    }
  }

  const formatHistoricalData = (data, currency) => {
    const filteredData = data.filter(entry => entry.Currency === currency)

    const formattedData = {}

    filteredData.forEach(entry => {
      const date = entry.Date
      const bank = entry.Bank
      const buyRate = entry.Buying
      const sellRate = entry.Selling

      if (!formattedData[date]) {
        formattedData[date] = { date }
      }
      formattedData[date][`${bank}_Buying`] =
        buyRate !== null ? parseFloat(buyRate).toFixed(2) : null
      formattedData[date][`${bank}_Selling`] =
        sellRate !== null ? parseFloat(sellRate).toFixed(2) : null
    })

    return Object.values(formattedData).sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    )
  }

  useEffect(() => {
    const storedBanks = JSON.parse(localStorage.getItem('selectedBanks'))
    const storedCurrencies = JSON.parse(
      localStorage.getItem('selectedCurrencies')
    )
    fetchExchangeRates()

    if (storedBanks) {
      setSelectedBanks(storedBanks)
    }
    if (storedCurrencies) {
      setSelectedCurrencies(storedCurrencies)
    }
  }, [])

  useEffect(() => {
    if (selectedCurrency) {
      fetchHistoricalData(selectedCurrency)
    }
  }, [selectedCurrency])

  useEffect(() => {
    if (selectedDate !== null) {
      fetchExchangeRates(selectedDate)
    } else {
      fetchExchangeRates()
    }
  }, [selectedDate])

  useEffect(() => {
    localStorage.setItem('selectedBanks', JSON.stringify(selectedBanks))
  }, [selectedBanks])

  useEffect(() => {
    localStorage.setItem(
      'selectedCurrencies',
      JSON.stringify(selectedCurrencies)
    )
  }, [selectedCurrencies])

  return (
    <div className='flex flex-col'>
      <div className='relative  h-full flex-col gap-2 flex justify-center space-y-5 items-center '>
        <div className='w-full absolute top-0 -z-[10] h-[400px] overflow-hidden'>
          <img
            src={images.banner_bg}
            alt=''
            className='opacity-30 object-cover'
          />
        </div>

        <h1 className='font-bold text-center bg-gradient-to-r from-blue-500 to-gray-500 text-transparent bg-clip-text pt-5'>
          Ethiopian Banks: Live Exchange Rates
        </h1>
        {/* <p className='bg-gradient-to-r from-blue-500 to-gray-500 text-transparent bg-clip-text m-0 w-[80%] md:w-fulltext-gray-600 text-16 text-center'>
          Artificial Intelligence powered exchange rate updates!
        </p> */}

        <div className='mb-[50px] top-[200px] w-full'>
          <CurrencyConverter
            exchangeRates={exchangeRates}
            setSelectedCurrency={setSelectedCurrency}
            historicalData={historicalData}
          />
        </div>
      </div>

      {/*<LatestUpdates />*/}

      <div className='w-full flex flex-col gap-10 mx-auto py-6 mt-10 bg-white '>
        {error ? (
          <div className='w-[90%] bg-red-200/30 p-4  mx-auto border-[1px] border-red-600'>
            <p className='text-red-500'>Error: {error} </p>
          </div>
        ) : (
          <>
            <div className='w-full px-6 m-auto md:max-w-7xl flex flex-col gap-10 items-center'>
              <CurrencyRateTable
                exchangeRates={exchangeRates}
                banks={banks}
                currencies={currencies}
                selectedBanks={selectedBanks}
                setSelectedBanks={setSelectedBanks}
                selectedDate={selectedDate}
                selectedCurrencies={selectedCurrencies}
                setSelectedDate={setSelectedDate}
                loading={loading}
              />
            </div>

            <div className='w-full px-6 m-auto md:max-w-7xl flex flex-col gap-10'>
              * National Bank of Ethiopia (NBE) provides Indicative Daily Exchange Rate calculated from FX transactions conducted by banks in the previous day. 
              It is meant to be only a reference exchange rate, but is not a mandatory transaction exchange rate.
            </div>

            <div className='w-full py-10 bg-secondary/5'>
              <div className='w-full m-auto md:max-w-7xl flex flex-col gap-10 items-center '>
                <BestTransaction
                  exchangeRates={exchangeRates}
                  banks={banks}
                  currencies={currencies}
                />
              </div>
            </div>

            {/* <div className='w-full py-10 bg-secondary/5'>
              <div className='w-full m-auto md:max-w-7xl flex flex-col gap-10 items-center '>
                <NBERateTable />
              </div>
            </div> */}

            <div className='w-full px-6 m-auto md:max-w-7xl flex flex-col gap-10 items-center '>
              <BinanceRate />
            </div>
          </>
        )}
        <Footer />
      </div>
    </div>
  )
}

export default HomePage
