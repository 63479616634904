import React from "react";
import moment from "moment";

function Timeline({ timeline, sortAscending, showEventDetails }) {
  if (!timeline) return null;

  const sortedEvents = [...timeline.events].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return sortAscending ? dateA - dateB : dateB - dateA;
  });

  return (
    <div className="p-3">
      <div className="relative px-4">
        <div className="absolute left-0 md:left-1/2 top-0 bottom-0 w-1 bg-gradient-to-b from-blue-500 to-purple-600 transform -translate-x-1/2"></div>
        {sortedEvents.map((event, index) => (
          <div
            key={index}
            className={`flex justify-center items-center mb-4`}
          >
            <div className={`w-full md:w-1/2 p-3 rounded-lg shadow-md relative transition transform hover:scale-105 hover:shadow-lg duration-300 ${index % 2 === 0 ? "ml-auto" : "mr-auto"}`}>
              <div
                className={`absolute w-4 h-4 bg-gradient-to-br  from-blue-500 to-purple-600 rounded-full top-0 ${index % 2 === 0 ? "-left-2 md:-right-2" : "-left-2"}`}
              ></div>
              <div className="text-blue-500 font-semibold text-sm">
                {moment(event.date).format("MMMM D, YYYY")}
              </div>
              <div className="text-lg font-semibold mb-1">{event.subtitle}</div>
              <div className="text-gray-600 mb-2 whitespace-pre-line text-sm">
                {event.description.substring(0, 150) +
                  (event.description.length > 150 ? "..." : "")}
              </div>
              <button
                className="text-blue-500 font-semibold mt-2 text-sm"
                onClick={() => showEventDetails(event)}
              >
                Read more
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Timeline;