import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMoneyBillWave,
  faExchangeAlt,
  faUniversity
} from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AnimatePresence, motion } from 'framer-motion'
import { Button } from '@material-tailwind/react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts'
import dayjs from 'dayjs'
import data from '../constants/data'

const { auth_token } = data;

const CurrencyConverter = ({ exchangeRates }) => {
  const [amount, setAmount] = useState(1)
  const [currency, setCurrency] = useState('USD')
  const [bank, setBank] = useState('')
  const [conversionResult, setConversionResult] = useState(null)
  const [isResultVisible, setIsResultVisible] = useState(false)

  const handleAmountChange = e => setAmount(e.target.value)
  const handleCurrencyChange = e => setCurrency(e.target.value)

  const handleBankChange = e => {
    setBank(e.target.value)
  }

  const [banks, setBanks] = useState([])
  const [bankNameMapping, setBankNameMapping] = useState({})

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await fetch(
          'https://birr-exchange-backend.vercel.app/api/v2/banks/', {
            headers: {
              'Authorization': `Bearer ${auth_token}`
            }
          }
        )
        const data = await response.json()
        const bankOptions = Object.keys(data)
          .map(key => ({
            key,
            name: data[key].name
          }))
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
  
        setBanks(bankOptions)
        setBankNameMapping(data)
        if (bankOptions.length > 0) {
          setBank(bankOptions[0].key) // Set the first bank as the default using the key
        }
      } catch (error) {
        console.error('Error fetching bank data:', error)
      }
    }
  
    fetchBanks()
  }, [])  

  const handleConvert = () => {
    const selectedBank = bankNameMapping[bank]
    if (exchangeRates[bank] && exchangeRates[bank][currency]) {
      const rate = parseFloat(exchangeRates[bank][currency].buy)
      const convertedAmount = (amount * rate).toFixed(2)
      const formattedAmount = Number(convertedAmount).toLocaleString()
      setConversionResult(`${amount} ${currency} = ${formattedAmount} ETB`)
      setIsResultVisible(true)
    } else {
      setConversionResult(
        'Exchange rate not available for the selected bank and currency.'
      )
      setIsResultVisible(true)
    }
  }

  const getExchangeRate = () => {
    if (exchangeRates[bank] && exchangeRates[bank][currency]) {
      return exchangeRates[bank][currency].buy
    }
    return null
  }

  const getReverseRate = () => {
    const rate = getExchangeRate()
    if (rate) {
      return (1 / parseFloat(rate)).toFixed(6)
    }
    return null
  }

  const handleClose = () => {
    setIsResultVisible(false)
  }

  const conversionResultVariants = {
    hidden: {
      y: '-1vh',
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 0.5
      }
    },
    exit: {
      y: '-1vh',
      opacity: 0,
      transition: {
        type: 'tween',
        duration: 0.5,
        delay: 0.3
      }
    }
  }

  return (
    <div className='w-full'>
      <div className='mx-auto w-full gap-0 md:max-w-5xl md:gap-4 flex flex-col md:flex-row items-center bg-white shadow-xl h-auto'>
        <div className='w-full md:w-1/2 max-w-5xl mx-auto rounded-md p-6 mb-3'>
          <h2 className='text-xl font-semibold mb-4'>
            <FontAwesomeIcon icon={faExchangeAlt} className='mr-2' />
            Convert
          </h2>
          <div className='flex flex-col space-y-4'>
            <div className='flex-1'>
              <label
                className='block text-gray-700 text-sm font-bold mb-2'
                htmlFor='amount'
              >
                Amount
              </label>
              <div className='relative'>
                <FontAwesomeIcon
                  icon={faMoneyBillWave}
                  className='absolute left-3 top-3 text-gray-500'
                />
                <input
                  type='text'
                  id='amount'
                  value={amount}
                  onChange={handleAmountChange}
                  className='shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                />
              </div>
            </div>
            <div className='flex space-x-4 mb-4'>
              <div className='flex-1'>
                <label
                  className='block text-gray-700 text-sm font-bold mb-2'
                  htmlFor='currency'
                >
                  Currency
                </label>
                <div className='relative'>
                  <FontAwesomeIcon
                    icon={faExchangeAlt}
                    className='absolute left-3 top-3 text-gray-500'
                  />
                  <select
                    id='currency'
                    value={currency}
                    onChange={handleCurrencyChange}
                    className='shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer'
                  >
                    <option className='px-5' value='USD'>
                      USD - US Dollar
                    </option>
                    <option className='px-5' value='EUR'>
                      EUR - Euro
                    </option>
                    <option className='px-5' value='GBP'>
                      GBP - British Pound
                    </option>
                    <option className='px-5' value='SAR'>
                      SAR - Saudi Riyal
                    </option>
                    <option className='px-5' value='AED'>
                      AED - UAE Dirham
                    </option>
                    {/* Add more options as needed */}
                  </select>
                </div>
              </div>
              <div className='flex-1'>
                <label
                  className='block text-gray-700 text-sm font-bold mb-2'
                  htmlFor='bank'
                >
                  Bank
                </label>
                <div className='relative'>
                  <span className='absolute left-3 top-2 text-gray-500'>
                    <FontAwesomeIcon icon={faUniversity} />
                  </span>

                  <select
                    id='bank'
                    value={bank}
                    onChange={handleBankChange}
                    className='shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer'
                  >
                    {banks.map((bankOption, index) => (
                      <option value={bankOption.key} key={index}>
                        {bankOption.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='flex justify-end mt-4'>
            <Button
              size='lg'
              onClick={handleConvert}
              className='bg-secondary capitalize hover:bg-secondary/80 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            >
              Convert
            </Button>
          </div>

          <AnimatePresence>
            {isResultVisible && conversionResult && (
              <motion.div
                variants={conversionResultVariants}
                initial='hidden'
                animate='visible'
                exit='exit'
                className='mt-4 p-4 rounded-md relative'
              >
                <button
                  onClick={handleClose}
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>

                <h2 className='font-semibold'>{conversionResult}</h2>
                <p className='font-semibold'>
                  1 {currency} = {getExchangeRate()} ETB
                </p>
                <p>
                  1 ETB = {getReverseRate()} {currency}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className='w-full h-full  md:w-1/2 max-w-5xl mx-auto rounded-md p-6 mb-3'>
          <CurrencyLineChart currency={currency} bank={bank} />
        </div>
      </div>
    </div>
  )
}

const CurrencyLineChart = ({ currency, bank }) => {
  const [period, setPeriod] = useState('week')
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const formatData = (data, bank) => {
    const bankData = data.filter(d => d.Bank === bank.replace(/ /g, '_'))
    const sortedBankData = bankData.sort((a, b) =>
      dayjs(a.Date).isBefore(dayjs(b.Date)) ? -1 : 1
    )
    setData(sortedBankData)
  }

  const fetchHistoricalData = async (currency, startdate) => {
    setLoading(true)
    try {
      const today = dayjs().format('YYYY-MM-DD')
      const response = await fetch(
        `https://birr-exchange-backend.vercel.app/api/v1/search/?start_date=${startdate}&end_date=${today}&currency=${currency}`, {
          headers: {
            'Authorization': `Bearer ${auth_token}`
          }
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      formatData(data, bank)
    } catch (error) {
      console.error('Failed to fetch historical data:', error)
      setError(error.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (currency) {
      fetchHistoricalData(
        currency,
        dayjs().subtract(1, period).format('YYYY-MM-DD')
      )
    }
  }, [currency, period, bank])

  return (
    <>
      <div className='w-full flex justify-center gap-2'>
        <Button
          variant={period === 'day' ? 'filled' : 'outlined'}
          className={`${
            period == 'day' ? 'text-white bg-secondary' : ' text-secondary '
          } ' capitalize border-secondary '`}
          size='sm'
          onClick={() => setPeriod('day')}
        >
          Day
        </Button>
        <Button
          variant={period === 'week' ? 'filled' : 'outlined'}
          className={`${
            period == 'week' ? 'text-white bg-secondary' : ' text-secondary '
          } ' capitalize border-secondary '`}
          size='sm'
          onClick={() => setPeriod('week')}
        >
          Week
        </Button>
        <Button
          variant={period === 'month' ? 'filled' : 'outlined'}
          className={`${
            period == 'month' ? 'text-white bg-secondary' : ' text-secondary '
          } ' capitalize border-secondary '`}
          size='sm'
          onClick={() => setPeriod('month')}
        >
          Month
        </Button>
        <Button
          variant={period === 'year' ? 'filled' : 'outlined'}
          className={`${
            period == 'year' ? 'text-white bg-secondary' : ' text-secondary '
          } ' capitalize border-secondary '`}
          size='sm'
          onClick={() => setPeriod('year')}
        >
          Year
        </Button>
      </div>
      {loading ? (
  <div className='w-full h-[400px] flex items-center justify-center'>
    <FontAwesomeIcon icon={faSpinner} spin size='2x' />
  </div>
) : error ? (
  <div className='w-full h-[400px] flex items-center justify-center'>
    <p className='text-red-500'>Error: {error} </p>
  </div>
) : (
  <>
    <div className='w-full h-[400px]'>
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id='colorRate' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#285eed' stopOpacity={0.9} />
              <stop offset='95%' stopColor='#1E90FF' stopOpacity={0} />
            </linearGradient>
            <linearGradient id='colorSelling' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#FF6347' stopOpacity={0.4} />
              <stop offset='95%' stopColor='#FF4500' stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey='Date' />
          <YAxis />
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip />
          <Legend />
          <Area
            type='monotone'
            stroke='#285eed'
            dataKey='Buying'
            fillOpacity={1}
            fill='url(#colorRate)'
          />
          <Area
            type='monotone'
            stroke='#FF6347'
            dataKey='Selling'
            fillOpacity={0.5}
            fill='url(#colorSelling)'
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  </>
)}
    </>
  )
}

export default CurrencyConverter
