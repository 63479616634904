import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  Chip,
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
  Button,
  IconButton,
} from '@material-tailwind/react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import dayjs from 'dayjs'
import data from '../constants/data'

const { auth_token } = data
const TABLE_HEAD = ['Date', 'Buying', 'Selling', 'Status']
const ROWS_PER_PAGE = 5 // Define rows per page

export function BinanceRate () {
  const [binanceData, setBinanceData] = useState({ table: [], chart: [] })
  const [error, setError] = useState(null)
  const [period, setPeriod] = useState('day')
  const [currentPage, setCurrentPage] = useState(1)

  const fetchBinanceData = async () => {
    try {
      const response = await fetch(
        'https://birr-exchange-backend.vercel.app/api/v1/binance/',
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()

      const latestData = data.reduce((acc, curr) => {
        const currDate = dayjs(curr.Date).format('YYYY-MM-DD') // Format the date
        acc[currDate] = { ...curr, Date: currDate } // Update Date in the object
        return acc
      }, {})

      const sortedTableData = Object.values(latestData).sort(
        (a, b) => dayjs(b.Date).unix() - dayjs(a.Date).unix()
      )

      const sortedChartData = Object.values(latestData).sort(
        (a, b) => dayjs(a.Date).unix() - dayjs(b.Date).unix()
      )

      setBinanceData({ table: sortedTableData, chart: sortedChartData })
    } catch (error) {
      console.error('Failed to fetch Binance data:', error)
      setError(error.message)
    }
  }

  useEffect(() => {
    fetchBinanceData()
  }, [])

  // Pagination Logic
  const startIndex = (currentPage - 1) * ROWS_PER_PAGE
  const paginatedData = binanceData.table.slice(
    startIndex,
    startIndex + ROWS_PER_PAGE
  )
  const totalPages = Math.ceil(binanceData.table.length / ROWS_PER_PAGE)

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1))
  }

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  }

  return (
    <Card className='h-full w-full '>
      <CardHeader
        floated={false}
        shadow={false}
        className='rounded-none  overflow-visible'
      >
        <div className='mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center'>
          <div>
            <Typography variant='h5' color='blue-gray'>
              Parallel Market Exchange Rate (ETB/USDT)
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className='px-0 '>
        <Tabs value='table'>
          <TabsHeader>
            <Tab key='table' value='table'>
              Table
            </Tab>
            <Tab key='chart' value='chart'>
              Chart
            </Tab>
          </TabsHeader>
          <TabsBody>
            <TabPanel key='table' value='table'>
              <div className='overflow-scroll'>
                <table className='w-full table-auto text-left '>
                  <thead>
                    <tr>
                      {TABLE_HEAD.map((head) => (
                        <th
                          key={head}
                          className='border-y border-blue-gray-100 bg-blue-gray-50/50 p-4'
                        >
                          <Typography
                            variant='small'
                            color='blue-gray'
                            className='font-normal leading-none opacity-70'
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map(({ Date, Buying, Selling }, index) => {
                      const isLast = index === paginatedData.length - 1
                      const classes = isLast
                        ? 'p-4'
                        : 'p-4 border-b border-blue-gray-50'

                      const prevData = binanceData.table[startIndex + index + 1]
                      let status = null
                      if (prevData) {
                        const percentageChange =
                          ((Buying - prevData.Buying) / prevData.Buying) * 100
                        let arrow
                        if (Buying > prevData.Buying) {
                          arrow = <span className='text-green-500'>↑</span>
                        } else if (Buying < prevData.Buying) {
                          arrow = <span className='text-red-500'>↓</span>
                        } else {
                          arrow = <span className='text-gray-500'>→</span>
                        }
                        status = (
                          <span>
                            {arrow} {percentageChange.toFixed(2)}%
                          </span>
                        )
                      }

                      return (
                        <tr key={index}>
                          <td className={classes}>
                            <div className='w-max'>
                              <Typography
                                variant='small'
                                color='blue-gray'
                                className='font-normal'
                              >
                                {Date}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className='w-max'>
                              <Chip
                                size='lg'
                                variant='ghost'
                                value={Buying.toFixed(4)}
                                color='green'
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className='w-max'>
                              <Chip
                                size='lg'
                                variant='ghost'
                                value={Selling.toFixed(4)}
                                color='red'
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className='w-max'>
                              {status !== null && (
                                <Typography
                                  variant='small'
                                  color='blue-gray'
                                  className='font-normal'
                                >
                                  {status}
                                </Typography>
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className='flex justify-between mt-4'>
                <Button
                  disabled={currentPage === 1}
                  onClick={handlePrevious}
                  variant='outlined'
                  size='sm'
                >
                  Previous
                </Button>
                <Typography variant='small' color='blue-gray'>
                  Page {currentPage} of {totalPages}
                </Typography>
                <Button
                  disabled={currentPage === totalPages}
                  onClick={handleNext}
                  variant='outlined'
                  size='sm'
                >
                  Next
                </Button>
              </div>
            </TabPanel>
            <TabPanel key='chart' value='chart'>
              <div className='w-full h-96'>
                <ResponsiveContainer width='100%' height={400}>
                  <AreaChart
                    data={binanceData.chart}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id='colorBuy' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopColor='#285eed' stopOpacity={0.8} />
                        <stop offset='95%' stopColor='#1E90FF' stopOpacity={0.1} />
                      </linearGradient>
                      <linearGradient id='colorSell' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopColor='#FF6347' stopOpacity={0.6} />
                        <stop offset='95%' stopColor='#FF4500' stopOpacity={0.1} />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey='Date' />
                    <YAxis />
                    <CartesianGrid strokeDasharray='3 3' />
                    <Tooltip />
                    <Legend />
                    <Area
                      type='monotone'
                      dataKey='Selling'
                      stroke='#FF6347'
                      fillOpacity={1}
                      fill='url(#colorSell)'
                    />
                    <Area
                      type='monotone'
                      dataKey='Buying'
                      stroke='#285eed'
                      fillOpacity={1}
                      fill='url(#colorBuy)'
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </TabPanel>
          </TabsBody>
        </Tabs>
      </CardBody>
    </Card>
  )
}

export default BinanceRate
